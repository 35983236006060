const linkResolver = doc => {
  if (doc.type === 'homepage') {
    return `/`
  }
	else if (doc.type === 'about') {
    return `/about`
  }
	else if (doc.type === 'project') {
		return `/${doc.slug}`
	}
  return '/'
}

module.exports = linkResolver