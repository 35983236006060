import React, { useRef, useEffect, useContext } from 'react'
import { ContextLoader } from '../../context'
import { gsap } from 'gsap'

const RollingBanner = ({ inlineSvg = null, text = null, duration = 5 }) => {

	const [isLoading] = useContext(ContextLoader)

	const containerEl = useRef(null)
	const rollerEl = useRef(null)
	const firstContentEl = useRef(null)

	let content = inlineSvg || text
	if (content.includes('<script>')) {
		content = ''
	}

	// Add viewbox and remove width and height attributes if svg is content
	if (inlineSvg !== null && typeof window !== 'undefined') {
		const shadowContainer = document.createElement('div')
		shadowContainer.innerHTML = inlineSvg
		const svgEl = shadowContainer.querySelector('svg')
		const svgWidth = svgEl.getAttribute('width')
		const svgHeight = svgEl.getAttribute('height')
		svgEl.setAttribute('viewbox', `0 0 ${svgWidth} ${svgHeight}`)
		svgEl.removeAttribute('width')
		svgEl.removeAttribute('height')
		content = svgEl.outerHTML
	}

	useEffect(() => {
		if (isLoading === true) return

		let translationDistance = null

		const rollContent = () => {
			gsap.set(rollerEl.current, {x: 0})
			gsap.to(
				rollerEl.current,
				duration,
				{
					x: -translationDistance + 'px',
					ease: 'linear',
					onComplete: rollContent
				}
			)
		}

		const handleResize = () => {
			translationDistance = firstContentEl.current.getBoundingClientRect().width
			containerEl.current.style.width = document.documentElement.clientWidth + 'px'
		}

		handleResize()
		rollContent()
		window.addEventListener('resize', handleResize)
		return () => {
			window.removeEventListener('resize', handleResize)
		}
	}, [isLoading])

	return (
		<section className="RollingBanner" ref={containerEl}>
			<div className="RollingBanner__Roller" ref={rollerEl}>
				<div className="RollingBanner__Content" ref={firstContentEl} dangerouslySetInnerHTML={{ __html: content }}></div>
				<div className="RollingBanner__Content" dangerouslySetInnerHTML={{ __html: content }}></div>
			</div>
		</section>
	)
}

export default RollingBanner